<template>
  <div>
 <el-row class="bgW">
      <el-col :span="18">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          
           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          
        </el-form>
      </el-col>
      <el-col :span="6" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary" class="mb10" size="mini" :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
      </el-row>
    <div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="市场" prop="market_name" />
            <ElTableColumn label="店面" prop="shop_name" />
            <ElTableColumn label="顾客姓名" prop="customer_name" />
            <ElTableColumn label="联系方式" prop="telephone" />
            <ElTableColumn label="疤痕类型" prop="scar_type_position" />
			 <ElTableColumn label="来源" prop="source_name" />
            <ElTableColumn label="首次进店时间" prop="first_time" />
            <ElTableColumn label="成交时间" prop="cj_time" />
            <ElTableColumn label="成交金额（新单）" prop="cj_money" />
            <ElTableColumn label="转介绍员工" prop="introduction_name" />
            <ElTableColumn label="提成奖金（成交金额的10%）" prop="introduction_money" width="180"/>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import pagination from '@/components/Pagination'
import { transferIntroduction } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
}
export default {
  name: 'OrderList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      list: [], // 数据展示
      loading:false,
      exportS: false,
    }
  },

  mounted () {
     let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01' 
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
  },
  methods: {
    getList(option) {
      this.loading = true
      if (option === "search") this.page = new Page();
      transferIntroduction({ ...this.search, ...this.page }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
        this.loading = false
      });
    },
    // 导出
    exportExcel() {
      this.exportS = true
      transferIntroduction({...this.search,export:1}).then(res => {
        window.open(res.data.url);
      });
      this.exportS = false
    },

  }
}
</script>

<style lang="scss" scoped>
.btnBox {
  text-align: right;
}
</style>
